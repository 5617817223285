import { from } from 'env-var';

const config = from(
  {
    NODE_ENV: process.env.NODE_ENV,
    NEXT_PUBLIC_APP_ENV: process.env.NEXT_PUBLIC_APP_ENV,
    NEXT_PUBLIC_SITE_URL: process.env.NEXT_PUBLIC_SITE_URL,
    NEXT_PUBLIC_SUPABASE_URL: process.env.NEXT_PUBLIC_SUPABASE_URL,
    NEXT_PUBLIC_SUPABASE_ANON_KEY: process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY,
    NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY:
      process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY,
    NEXT_PUBLIC_SP_TERMS_URL: process.env.NEXT_PUBLIC_SP_TERMS_URL,
    NEXT_PUBLIC_SP_PRIVACY_URL: process.env.NEXT_PUBLIC_SP_PRIVACY_URL,
    NEXT_PUBLIC_SP_CONTACTS_URL: process.env.NEXT_PUBLIC_SP_CONTACTS_URL,
    NEXT_PUBLIC_SP_API_URL: process.env.NEXT_PUBLIC_SP_API_URL,
    NEXT_PUBLIC_EXTENSION_DATA_PROVIDER:
      process.env.NEXT_PUBLIC_EXTENSION_DATA_PROVIDER,
    NEXT_PUBLIC_SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
    NEXT_PUBLIC_LOG_LEVEL: process.env.NEXT_PUBLIC_LOG_LEVEL,
    NEXT_PUBLIC_GA_MEASUREMENT_ID: process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID,
    NEXT_PUBLIC_SP_EXTENSION_DOWNLOAD_URL:
      process.env.NEXT_PUBLIC_SP_EXTENSION_DOWNLOAD_URL,
    NEXT_PUBLIC_CAPTCHA_SITE_KEY: process.env.NEXT_PUBLIC_CAPTCHA_SITE_KEY,
  },
  {
    asRelaxedUrl: (value) => {
      try {
        new URL(value);
        return value;
      } catch (error) {
        throw new Error(`Expected a valid URL but got: ${value}`);
      }
    },
  },
);

/**
 * The object instance that contains all the client environment variables. Don't use this object in the server side code.
 *
 * Note: all the client side environment variables should be prefixed with `NEXT_PUBLIC_` (except NODE_ENV) and should never contain any sensitive information.
 * Refer to README.md for more information.
 */
export const clientEnv = {
  nodeEnv: config
    .get('NODE_ENV')
    .required()
    .asEnum(['development', 'test', 'production']),
  appEnv: config
    .get('NEXT_PUBLIC_APP_ENV')
    .required()
    .asEnum(['prod', 'dev', 'demo', 'local']),
  siteUrl: config.get('NEXT_PUBLIC_SITE_URL').required().asRelaxedUrl(),
  supabaseUrl: config.get('NEXT_PUBLIC_SUPABASE_URL').required().asRelaxedUrl(),
  supabaseAnonKey: config
    .get('NEXT_PUBLIC_SUPABASE_ANON_KEY')
    .required()
    .asString(),
  stripePublishableKey: config
    .get('NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY')
    .required()
    .asString(),
  spTermsUrl: config.get('NEXT_PUBLIC_SP_TERMS_URL').required().asRelaxedUrl(),
  spPrivacyUrl: config
    .get('NEXT_PUBLIC_SP_PRIVACY_URL')
    .required()
    .asRelaxedUrl(),
  spContactsUrl: config
    .get('NEXT_PUBLIC_SP_CONTACTS_URL')
    .required()
    .asRelaxedUrl(),
  spApiUrl: config.get('NEXT_PUBLIC_SP_API_URL').required().asRelaxedUrl(),
  spExtensionDownloadUrl: config
    .get('NEXT_PUBLIC_SP_EXTENSION_DOWNLOAD_URL')
    .required()
    .asRelaxedUrl(),
  extensionDataProvider: config
    .get('NEXT_PUBLIC_EXTENSION_DATA_PROVIDER')
    .required()
    .asEnum(['default', 'premium']),
  sentryDsn: config.get('NEXT_PUBLIC_SENTRY_DSN').required().asRelaxedUrl(),
  logLevel: config
    .get('NEXT_PUBLIC_LOG_LEVEL')
    .default('info')
    .asEnum(['debug', 'info', 'warn', 'error']),
  gaMeasurementId: config
    .get('NEXT_PUBLIC_GA_MEASUREMENT_ID')
    .required()
    .asString(),
  captchaSiteKey: config
    .get('NEXT_PUBLIC_CAPTCHA_SITE_KEY')
    .required()
    .asString(),
} as const;

export function isProductionBuild(): boolean {
  return clientEnv.nodeEnv === 'production';
}
