import {
  OpenAPI,
  type SpApiErrorResponse,
  type SpApiError as SpApiErrorType,
} from './gen';

export type ApiClientConfig = {
  baseUrl: string;
  requestInterceptors?: Array<
    (arg0: RequestInit) => RequestInit | Promise<RequestInit>
  >;
  responseInterceptors?: Array<
    (arg0: Response) => Response | Promise<Response>
  >;
};

export function configureApiClient({
  baseUrl,
  requestInterceptors,
  responseInterceptors,
}: ApiClientConfig) {
  OpenAPI.BASE = baseUrl;
  if (requestInterceptors) {
    requestInterceptors.forEach((interceptor) => {
      OpenAPI.interceptors.request.use(interceptor);
    });
  }

  if (responseInterceptors) {
    responseInterceptors.forEach((interceptor) => {
      OpenAPI.interceptors.response.use(interceptor);
    });
  }
}

export class SpApiError extends Error implements SpApiErrorType {
  constructor(message: string, status: number) {
    super(message);
    this.status = status;
    this.description = message;
  }

  public status: number;

  public description: string;
}

/**
 * Unwraps the API response to extract the data or throws an error if the response contains an error.
 *
 * @param response - The API response object containing either the data or an error.
 * @returns The extracted data from the response.
 * @throws SpApiError when the response contains an error with the specified message and status.
 */
export function unwrapApiResponse<T>(
  response: { data: T } | SpApiErrorResponse,
): T {
  if ('error' in response) {
    throw new SpApiError(response.error.description, response.error.status);
  }

  return response.data;
}

export * from './gen';
